/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@layer components {
  
  .accordion-header.active button.toggle svg {
    @apply transition ease-in-out duration-300;
    transform: rotate(90deg);
  }

  .ql-container.ql-snow {
    @apply border-none !important;
  }

  .ql-editor{
    @apply px-0 !important;
  }

  .ql-toolbar.ql-snow{
    @apply shadow-custom !important;
  }
  .screen {
    @apply bg-gray-500 !important;
  }
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "Arial";
  font-family: "Arial", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="comic-sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before {
  content: "Comic Sans";
  font-family: "Comic Sans MS", cursive;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier-new"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
  content: "Courier New";
  font-family: "Courier New", monospace;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  content: "Georgia";
  font-family: Georgia, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
  content: "Helvetica";
  font-family: Helvetica, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lucida"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before {
  content: "Lucida Sans";
  font-family: "Lucida Sans", sans-serif;
}


.ql-font-comic-sans {
  font-family: "Comic Neue", cursive;
}

/* Arial */
.ql-font-arial {
  font-family: "Arial", sans-serif;
}

/* Comic Sans */
.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive;
}

/* Courier New */
.ql-font-courier-new {
  font-family: "Courier New", monospace;
}

/* Georgia */
.ql-font-georgia {
  font-family: "Georgia", serif;
}

/* Helvetica */
.ql-font-helvetica {
  font-family: "Helvetica", sans-serif;
}

/* Lucida */
.ql-font-lucida {
  font-family: "Lucida Sans", sans-serif;
}

/* Times New Roman */
.ql-font-times-new-roman {
  font-family: "Times New Roman", serif;
}

/* Verdana */
.ql-font-verdana {
  font-family: "Verdana", sans-serif;
}
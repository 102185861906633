.default-tab {
    background: white;
    border: 2px solid transparent; /* Transparent border */
    border-radius: 16px; /* Optional: for rounded corners */
    transition: background 0.3s ease, border 0.3s ease;
}

.default-tab:hover {
    background: #E9EBED; /* Change background on hover */
    border: 2px solid #E9EBED; /* Visible border on hover */
}


.selected-tab {
    border-radius: 20px 20px 0 0; /* Curved top border */
    border: 2px solid #E9EBED;
    border-bottom: none; /* Removes the bottom border */
    background: white;
    transition: all 0.3s ease-in-out;
}


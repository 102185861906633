/* Table Styles */
.tabulator {
  /* border: 1px solid #f3f4f6; */
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-top: 1rem;
  width: 98%;
  margin: 1rem auto;
  background-color: #fff;
}

/* Remove default hover color */
.tabulator .tabulator-row{
  background-color: transparent;
}

/* Table Header */
.tabulator .tabulator-header {
  background-color: #2788e9;
  color: #fff;
  font-weight: bold;
  border: none;
  /* border-bottom: 1px solid #f3f4f6; */
}

.tabulator .tabulator-header .tabulator-col {
  background-color: #2788e9;
  border: none;
}


.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  background-color: transparent;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=none] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #fff;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
}


/* Table Rows */
.tabulator .tabulator-row {
  background-color: #fff;
}

.tabulator .tabulator-cell {
  border-bottom: 1px solid #e0e0e0;
  border-right: none;
  padding: 10px;
  height: 40px;
}

/* Table Row Hover */
.tabulator .tabulator-row:hover {
  background-color: #dbeafe;
}

/* Table Pagination */
.tabulator .tabulator-footer {
  background-color: #f5faff;
  border-top: 1px solid #e0e0e0;
}

.tabulator .tabulator-pagination .tabulator-page {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.tabulator .tabulator-pagination .tabulator-page.active {
  background-color: #0056b3;
}

/* Centered Text */
.tabulator .center-text {
  text-align: center;
}


.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  box-sizing: border-box;
  position: relative;
  padding: 4px;
  margin-left: 0.2rem;
}

.tabulator .action-icon {
  color: #4b5563; /* Dark gray color */
  width: 20px; /* Adjust the width as needed */
  /* height: 20px; Adjust the height as needed */
}

/* br{
  display: none;
} */

.action-btn {
  background: none;
  border: none;
  /* font-size: 24px; */
  cursor: pointer;
  position: relative;
}

.popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure the popup is above other content */
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 20;
  width: 12rem; /* Adjust width as needed */
  padding: 0.5rem 0;
  margin-top: 0.5rem;
  background-color: #fff;
  border-radius: 0.375rem; /* 6px rounded */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.75rem;
  font-size: 0.875rem; /* 14px */
  color: #4b5563; /* Tailwind text-gray-600 */
  text-transform: capitalize;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-menu button{
  margin-bottom: 0.3rem;
}

.dropdown-menu button:hover {
  background-color: #f3f4f6; /* Tailwind hover:bg-gray-100 */
  color: #1a202c; /* Tailwind dark:text-gray-300 */
}

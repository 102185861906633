@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');


*{
  padding: 0 ;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Inter', sans-serif; */
}

.select__control{
  height:48px !important;
}

.cover-image{
  background: url('./Components/assets/image.png');
  /* object-fit: cover; */
  /* background-position: -80px -157px; */
  /* background-position: center; */
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px; 
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 50%;
  
  cursor: pointer;
}

/* Default state */
input[type="radio"]:checked {
  background-color: #382F8A; /* Change this color to your preferred one */
}

.ql-container .ql-editor {
  font-size: 16px !important; /* Default font size */
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "Arial";
  font-family: "Arial", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="comic-sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before {
  content: "Comic Sans";
  font-family: "Comic Sans MS", cursive;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier-new"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
  content: "Courier New";
  font-family: "Courier New", monospace;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  content: "Georgia";
  font-family: Georgia, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
  content: "Helvetica";
  font-family: Helvetica, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lucida"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before {
  content: "Lucida Sans";
  font-family: "Lucida Sans", sans-serif;
}


.ql-font-comic-sans {
  font-family: "Comic Neue", cursive;
}

/* Arial */
.ql-font-arial {
  font-family: "Arial", sans-serif;
}

/* Comic Sans */
.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive;
}

/* Courier New */
.ql-font-courier-new {
  font-family: "Courier New", monospace;
}

/* Georgia */
.ql-font-georgia {
  font-family: "Georgia", serif;
}

/* Helvetica */
.ql-font-helvetica {
  font-family: "Helvetica", sans-serif;
}

/* Lucida */
.ql-font-lucida {
  font-family: "Lucida Sans", sans-serif;
}

/* Times New Roman */
.ql-font-times-new-roman {
  font-family: "Times New Roman", serif;
}

/* Verdana */
.ql-font-verdana {
  font-family: "Verdana", sans-serif;
}

/* Add similar rules for other fonts */
